import ecommerce from '../../../../../ecommerce/ecwid/custom';
import dom from '../../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../../wrapper/WidgetWrapper';

class CartPage extends WidgetWrapper {
  init = async () => {
    try {
      const elCartBlock = dom.getElement('#mfe-docking-container');
      const section = elCartBlock?.closest('.s-section');

      if (section) {
        section.removeAttribute('style');
        dom.removeElement(dom.getElement('.section_bg', section));
      }

      dom.updateStyle(elCartBlock, { minHeight: `${Math.round(dom.window.innerHeight / 2)}px` });
      dom.addClass(elCartBlock, 'spinner');
      await ecommerce.provider.cart.init();
      await ecommerce.provider.cart.openCart();
      dom.removeClass(elCartBlock, 'spinner');

      const elTitle = dom.getElement('title');

      elTitle.innerHTML = 'Your cart';
    } catch (error) {
      console.error(error);
    }
  };
}

export default CartPage;
