import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import escape from 'lodash/escape';
import dom from '../../../../../wrapper/DomWrapper';

import {
  PRODUCT_OPTION_TYPES,
  PRODUCT_OPTIONS_GROUP_TEMPLATE,
} from '../../custom/Product/constants';

export const PRODUCT_OPTIONS_RADIO_ITEM_TEMPLATE = (
  name,
  value,
  label = '',
  disabled
) => (`
<div class="radio-wrap">
    <input
        type="radio"
        id="${name}-${label}-${value}"
        class="radio-btn"
        name="${name}"
        value="${value}"
        ${disabled ? 'disabled' : ''}
    >
    <label for="${name}-${label}-${value}" class="radio-label">
    <span class="radio-label-text">
        <span class="choice-title">
            ${label}
        </span>
    </span>
    </label>
</div>
`);

class ProductOptions {
  constructor(
    name,
    choice,
    currencyFormatter = (price) => price
  ) {
    this.name = escape(name);
    this.choice = this.formatChoice(choice);
    this.currencyFormatter = currencyFormatter;
    this.html = '';

    this.createRelatedList();
  }

  formatChoice = (choice) => {
    if (!isArray(choice)) return choice;

    return choice.map((data) => ({
      ...data,
      value: data.text,
      text: escape(data.text),
    }));
  };

  createRelatedList = () => {
    const options = this.choice.reduce((group, {
      disabled,
      text,
    }) => group.concat(
      PRODUCT_OPTIONS_RADIO_ITEM_TEMPLATE(
        this.name,
        text,
        text,
        disabled
      )
    ), '');

    this.html = PRODUCT_OPTIONS_GROUP_TEMPLATE(
      PRODUCT_OPTION_TYPES.RADIO,
      this.name,
      options
    );
  };

  connect = (element, onChange) => this.connectRelatedList(element, onChange);

  connectRelatedList = (
    element,
    onChange = () => null
  ) => {
    const optionsElement = dom.createElement('div');

    dom.addHtml(optionsElement, this.html);
    element.appendChild(optionsElement);

    const inputs = this.getInputsByProperty({
      selector: '.radio-wrap>input',
      property: 'name',
      parent: optionsElement,
    });

    if (!isArray(inputs)) return;

    inputs.forEach((input) => {
      dom.on(input, 'change', onChange);
    });
  };

  getInputsByProperty({
    selector = 'input',
    property = 'name',
    parent = dom.document,
  }) {
    const inputs = dom.getCollection(selector, parent);

    if (isNil(inputs)) return [];

    return [...inputs].filter((input) => escape(input[property]) === this.name);
  }
}

export default ProductOptions;
