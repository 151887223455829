export const PRODUCT_CONTAINER_ID = 'js-product-page-wrapper';
export const PRODUCT_SPINNER_ID = 'js-product-spinner';
export const PRODUCT_GALLERY_ID = 'js-product-gallery';
export const PRODUCT_SIDEBAR_ID = 'js-product-sidebar';
export const PRODUCT_RETRY_ID = 'js-product-retry';
export const PRODUCT_RETRY_BUTTON_ID = 'js-product-retry';
export const PRODUCT_TITLE_ID = 'js-product-title';
export const PRODUCT_DESCRIBE_ID = 'js-product-description';
export const PRODUCT_REGULAR_PRICE_ID = 'js-product-regular-price';
export const PRODUCT_PREVIOUS_PRICE_ID = 'js-product-previous-price';
export const PRODUCT_SAVED_PERCENT_ID = 'js-product-discount-price';
export const PRODUCT_STATUS_ID = 'js-product-status';
export const PRODUCT_PREVIEW_ID = 'js-product-preview';
export const PRODUCT_NO_PREVIEW_ID = 'js-product-no-preview';
export const PRODUCT_CAROUSEL_ID = 'js-product-carousel';
export const PRODUCT_IN_STOCK_ID = 'js-product-left-in-stock';
export const PRODUCT_QUANTITY_IN_CART_ID = 'js-product-count-in-cart';
export const PRODUCT_CHECKOUT_BUTTON_ID = 'js-product-go-to-checkout';
export const PRODUCT_BUY_BUTTON_ID = 'js-product-buy-button';
export const PRODUCT_OPTIONS_ID = 'js-product-options';
export const PRODUCT_QUANTITY_INPUT_ID = 'js-product-quantity-input';
export const PRODUCT_SOLD_OUT_ID = 'js-product-sold-out';

export const PRODUCT_PREVIEW_LIGHT_SELECTOR = '.js-widget[data-widget="product-preview"]';
export const PRODUCT_CAROUSEL_LIGHT_SELECTOR = '.js-widget[data-widget-id="product-carousel"]';

export const DEFAULT_CURRENCY_PREFIX = '$';

export const DEFAULT_CURRENCY_GROUP_LENGTH = 3;

export const DEFAULT_CURRENCY_FORMAT_OPTIONS = {
  currencyGroupSeparator: ' ',
  currencyDecimalSeparator: '.',
  currency: '',
};

export const EMPTY_PRODUCT_CAROUSEL_THUMBNAIL = [
  {
    id: 'product-empty-thumbnail',
    url: '#empty',
  },
];

export const PRODUCT_OPTION_TYPES = {
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  SELECT: 'SELECT',
};

export const PRICE_MODIFIER_TYPES = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENT: 'PERCENT',
};

export const CAROUSEL_ITEM_TEMPLATE = (src, id) => `
<div id="${id}" class="gallery-item" style="cursor:pointer;padding:10px">
    <div class="_error-wrap">
    <div class="gallery-thumb square cropped">
        <img class="lazy" data-src="${src}" alt="">
    </div>
   </div>
</div>
`;

export const PRODUCT_OPTIONS_GROUP_TEMPLATE = (type, name, options = '') => (`
<div class="product-page__options_${type}" >
    <div class="product-page__option-name">
        ${name}
    </div>
    <div class="${type}-box">
        ${options}
    </div>
</div>
`);

export const PRODUCT_OPTIONS_RADIO_ITEM_TEMPLATE = (
  name,
  value,
  label = '',
  modifierLabel = '',
  checked = false
) => (`
<div class="radio-wrap">
    <input
        type="radio"
        id="${name}-${label}-${value}"
        class="radio-btn"
        name="${name}"
        value="${value}"
        ${checked ? 'checked' : ''}
    >
    <label for="${name}-${label}-${value}" class="radio-label">
    <span class="radio-label-text">
        <span class="choice-title">
            ${label}
            <span class="choice-modifier">${modifierLabel}</span>
        </span>
    </span>
    </label>
</div>
`);

export const PRODUCT_OPTIONS_CHECKBOX_ITEM_TEMPLATE = (
  name,
  value,
  label = '',
  modifierLabel = '',
  checked = false
) => (`
<div class="checkbox-wrap">
    <input
        type="checkbox"
        id="${name}-${label}-${value}"
        class="checkbox"
        name="${name}"
        value="${value}"
        ${checked ? 'checked' : ''}
    >
    <label
        for="${name}-${label}-${value}"
        class="checkbox__label"
    >
        <i class="icon">
            <svg width="20px" height="16.666666666666668px">
                <use xlink:href="#global/check"></use>
            </svg>
        </i>
        <span class="checkbox__text">
            <span class="choice-title">
                ${label}
                <span class="choice-modifier">${modifierLabel}</span>
            </span>
        </span>
    </label>
</div>
`);

export const PRODUCT_OPTIONS_SELECT_ITEM_TEMPLATE = (name) => (`
<select
    id="${name}"
    style="display: none;"
    name="${name}"
></select>
`);

export const PRODUCT_BADGES_TEMPLATE = (badges = []) => badges.map((badge) => `<div class="label__text ${badge.className}">${badge.text}</div>`);
