import isPlainObject from 'lodash/isPlainObject';

import getStateValue from '../../../../../../helpers/getStateValue';

import { MAP_DASH_SORT } from './index';

const paramsToQueryString = (params) => Object
  .keys(params)
  .map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

const ecommerceDashApiService = getStateValue('apiUrls.ecomDashApiUrl');

export default {
  categories: (instanceId, params = {}) => `${ecommerceDashApiService}product/all?instanceId=${instanceId}&${paramsToQueryString(
    params
  )}`,
  allProducts: (instanceId, params) => {
    const sortBy = MAP_DASH_SORT[params.sortBy];

    return `${ecommerceDashApiService}product/all?instanceId=${instanceId}&${paramsToQueryString(
      {
        limit: params.limit,
        offset: params.offset,
        ...(params.category && { categoryId: params.category }),
        ...(isPlainObject(sortBy) && { [sortBy.key]: sortBy.value }),
      }
    )}`;
  },
  product: (instanceId, productId) => `${ecommerceDashApiService}product/details/?instanceId=${instanceId}&productId=${productId}`,
  products: (instanceId) => `${ecommerceDashApiService}product/all?instanceId=${instanceId}`,
};
